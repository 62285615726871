import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import zhCn from "element-plus/dist/locale/zh-cn.mjs"
import 'normalize.css'
import '@/assets/css/base.css'
import { createPinia } from 'pinia'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
//导入接口------挂全局
import api from '@/api'
//  挂全局 provied inject
const app = createApp(App)
app.provide('$api', api)
app.use(ElementPlus, {
    locale: zhCn,
})
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(ElementPlus)
app.use(createPinia())
app.use(store).use(router).mount('#app')
