<template>
  <el-menu
    active-text-color="skyblue"
    background-color="#4874cb"
    class="el-menu-vertical-demo"
    :default-active="$route.path"
    router
    :collapse="props.isCollapse"
    text-color="#fff"
    @open="handleOpen"
    @close="handleClose"
  >
    <el-menu-item>
      <template #title>
        <span>{{ !isCollapse ? "派达行管理系统" : "后台" }}</span>
      </template>
    </el-menu-item>

    <el-menu-item index="/list">
      <Paperclip style="width: 1em; height: 1em; margin-right: 8px" />
      <template #title>
        <span>订单管理</span>
      </template>
    </el-menu-item>
    <el-menu-item index="/refundList">
      <DocumentChecked style="width: 1em; height: 1em; margin-right: 8px" />
      <template #title>
        <span>退款申请</span>
      </template>
    </el-menu-item>
    <el-menu-item index="/money">
      <Refresh style="width: 1em; height: 1em; margin-right: 8px" />
      <template #title>
        <span>提现申请</span>
      </template>
    </el-menu-item>
    <el-menu-item index="/riderApply">
      <UserFilled style="width: 1em; height: 1em; margin-right: 8px" />
      <template #title>
        <span>入驻申请</span>
      </template>
    </el-menu-item>
    <el-menu-item index="/configuration">
      <BellFilled style="width: 1em; height: 1em; margin-right: 8px" />
      <template #title>
        <span>配置管理</span>
      </template>
    </el-menu-item>
  </el-menu>
</template>
<script  setup>
// 接收父组件定义得折叠
const props = defineProps(["isCollapse"]);
const handleOpen = (key, keyPath) => {};
const handleClose = (key, keyPath) => {
  console.log(key, keyPath);
};
</script>
<style scoped lang="scss">
.el-menu {
  border-right: none;
}
.el-menu.is-active {
  background: #1e78bf !important;
  color: #fff;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>