// 产品管理页面接口
import request from "@/utlis/request"
// 订单列表
const orderList = "/sys/orders/list"
// 退款申请列表
const ordersRefund = "/sys/orders/refund-list"
// 退款申请审核
const ordersEdit = "/sys/orders/audit-refund"
// 提现列表
const MoneyList = "/sys/cash/list"
// 提现审核
const cashAudit = "/sys/cash/audit"
// 接单员申请列表
const takerList = "/sys/taker/list"
// 接单员审核
const takerAudit = "/sys/taker/audit"
// 配置管理
const getConfigs = "/sys/configs/key?configKey="
// 配置管理修改
const configsAudit = "sys/configs/edit"
// 定义方法
export default {
    orderList(params) {
        return request({ url: orderList, method: 'get', params: params })
    },
    MoneyList(params) {
        return request({ url: MoneyList, method: 'get', params: params })

    },
    takerList(params) {
        return request({ url: takerList, method: 'get', params: params })
    },
    takerAudit(data) {
        return request.post(takerAudit, data, {
            headers: {
                "Content-Type": "application/json",
            }
        })
    },
    cashAudit(data) {
        return request.post(cashAudit, data, {
            headers: {
                "Content-Type": "application/json",
            }
        })
    },
    getConfigs(key) {
        return request({ url: getConfigs + key, method: 'get' })
    },
    ordersEdit(data) {
        return request.post(ordersEdit, data, {
            headers: {
                "Content-Type": "application/json",
            }
        })

    },
    ordersRefund(params) {
        return request({ url: ordersRefund, method: 'get', params: params })

    },
    configsAudit(data) {
        return request.post(configsAudit, data, {
            headers: {
                "Content-Type": "application/json",
            }
        })
    }
}