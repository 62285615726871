<template>
  <div class="menu"><Menu :isCollapse="isCollapse"></Menu></div>
  <div class="content" :class="{ isClose: isCollapse }">
    <Content
      :isCollapse="isCollapse"
      @changeCollapse="changeCollapse"
    ></Content>
  </div>
</template>
<script setup>
import Menu from "./menu/Menu.vue";
import Content from "./content/Content.vue";
import { ref } from "vue";

//定义折叠效果得变量在父组件  分别传给两个需要得子组件
const isCollapse = ref(false);
const changeCollapse = () => {
  isCollapse.value = !isCollapse.value;
};
</script>

<style lang="scss" scoped>
.menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: #4874cb;
}
.content {
  padding-left: 200px;
}
.isClose {
  padding-left: 64px;
}
</style>