<template>
  <div class="wrapper">
    <el-row>
      <el-col :span="12" :xs="0"></el-col>
      <el-col :span="12" :xs="24">
        <el-form :model="loginForm" :rules="rules" class="login_form">
          <h2>派达行管理系统</h2>
          <el-form-item prop="userId">
            <el-input :prefix-icon="User" v-model="loginForm.userId" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              :prefix-icon="Lock"
              type="password"
              v-model="loginForm.password"
              show-password
            />
          </el-form-item>
          <el-form-item>
            <el-button
              :loading="loading"
              type="primary"
              class="login_btn"
              @click="submitForm"
            >
              登录
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <!-- <el-form
      :model="ruleForm2"
      :rules="rules2"
      ref="ruleForm2"
      label-position="left"
      label-width="0px"
      class="demo-ruleForm login-container login_form"
    >
      <h3 class="title">系统登录</h3>
      <el-form-item prop="account">
        <el-input
          type="text"
          v-model="loginForm.userId"
          auto-complete="off"
          placeholder="账号"
        ></el-input>
      </el-form-item>
      <el-form-item prop="checkPass">
        <el-input
          type="password"
          v-model="loginForm.password"
          auto-complete="off"
          placeholder="密码"
        ></el-input>
      </el-form-item>
      <el-checkbox v-model="checked" checked class="remember"
        >记住密码</el-checkbox
      >
      <el-form-item style="margin-left: 145px">
        <el-button type="primary" @click="submitForm" :loading="logining"
          >登录</el-button
        >
        <el-button @click.native.prevent="handleReset2">重置</el-button>
      </el-form-item>
    </el-form> -->
  </div>
</template>

<script  setup>
import { inject, onMounted, reactive, ref, provide } from "vue";
import router from "@/router";
import { ElMessage } from "element-plus";
import { User, Lock } from "@element-plus/icons-vue";
const api = inject("$api");
const checked = ref(false);
const Base64 = require("js-base64").Base64;
const loginForm = reactive({
  userId: "",
  password: "",
});
const rules = {
  userId: [{ required: true, message: "请输入账号", trigger: "blur" }],
  password: [{ required: true, message: "请输入密码", trigger: "blur" }],
};
onMounted(() => {
  let username = localStorage.getItem("userId");
  if (username) {
    loginForm.userId = localStorage.getItem("userId");
    loginForm.password = Base64.decode(localStorage.getItem("password")); // base64解密
  }
});

const submitForm = async () => {
  let res = await api.login({
    adminName: loginForm.userId,
    adminPwd: loginForm.password,
  });
  console.log("登录接口请求----", res.data);
  if (res.data.code === 200) {
    console.log(res.data.data.token);
    let password = Base64.encode(loginForm.password); // base64加密
    localStorage.setItem("userId", loginForm.userId);
    localStorage.setItem("token", res.data.data.token);
    localStorage.setItem("password", password);
    //登录成功了---进入首页
    router.push("/list");

    loginForm.password = "";
    loginForm.userId = "";
  } else {
    ElMessage.error("账户密码错误，请重新输入");

    /* ------ http登录请求 ------ */
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100vh;
  background: url("../../assets/images/login4.png") no-repeat;
  background-size: cover;
  .login_form {
    position: relative; //相对定位
    width: 40%;
    top: 30vh;
    background: url("../../assets/images/login3.jpeg") no-repeat;
    background-size: cover; //覆盖
    padding: 40px;
    border-radius: 5%;
    h1 {
      font-size: 40px;
      color: #fff;
    }
    h2 {
      font-size: 20px;
      color: #fff;
      margin: 20px 0;
    }
    .login_btn {
      width: 100%;
    }
  }
}
</style>
