<template>
  <!-- 1. 顶部区域 -->
  <div class="header">
    <el-row>
      <el-col :span="4">
        <span
          v-if="!props.isCollapse"
          class="iconfont icon-right-indent"
          @click="changeOpen"
        ></span>
        <span
          v-else
          class="iconfont icon-left-indent"
          @click="changeOpen"
        ></span>
      </el-col>
      <el-col :span="19">
        <div class="right">
          <span>{{ time }}</span> | <span>欢迎 {{ user }}</span> |
        </div>
      </el-col>
      <span @click="loginOut"
        ><el-col :span="1">
          <el-icon :size="18"> <SwitchButton /> </el-icon> </el-col
      ></span>
    </el-row>
  </div>
  <!-- 2. 路由出口 -->
  <div class="wrapper">
    <router-view />
  </div>
</template>

<script setup>
import dayjs from "dayjs";
import { ref } from "vue";

const props = defineProps(["isCollapse"]);
const emit = defineEmits(["changeCollapse"]);
const time = ref("");
const user = ref(localStorage.getItem("userId"));

time.value = dayjs().format("YYYY年MM月DD日 HH:mm:ss");
import router from "@/router";

//点击折叠效果 -- 修改父组件变量  isCollapse
const changeOpen = () => {
  emit("changeCollapse");
};

const loginOut = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  localStorage.removeItem("password");
  router.replace("/login");
};
</script>

<style lang="scss" scoped>
.header {
  background: #91ace0;
  height: 50px;
  line-height: 50px;

  color: #fff;
  .iconfont {
    font-size: 22px;
    cursor: pointer;
  }
  .right {
    width: 300px;
    font-size: 14px;
    float: right;
    right: 0;
    span {
      margin-top: 10px;
      padding: 0 6px;
      text-align: center;
    }
    .icon {
      font-size: 16px;
      line-height: 25px;
    }
  }
  .el-icon {
    height: 2.8em;
  }
}
.wrapper {
  padding: 10px;
}
</style>