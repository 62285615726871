import { createRouter, createWebHistory } from 'vue-router'
import Layout from '../views/layout/Index.vue'
import Login from '../views/login/Login.vue'

// 订单管理
const List = () => import('../views/product/List.vue')
// 退款申请
const RefundList = () => import('../views/refundList/RefundList.vue')
//提现申请
const Money = () => import('../views/money/Money.vue')
// 入驻申请
const RiderApply = () => import('../views/riderApply/RiderApply.vue')
// //配置管理
const configuration = () => import('../views/configuration/Configuration.vue')

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: '/login',
    children: [

      {
        path: '/list',
        name: 'list',
        component: List,


      },
      {
        path: 'refundList',
        name: 'refundList',
        component: RefundList,

      },

      {

        path: 'money',
        name: 'money',
        component: Money,
      },
      {
        path: 'riderApply',
        name: 'riderApply',
        component: RiderApply,

      },
      {
        path: 'configuration',
        name: 'configuration',
        component: configuration,
      }

    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    // 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
    location.reload();
    const targetPath = $router.history.pending.fullPath;
    $router.replace(targetPath);
  }
})


export default router


router.beforeEach((to, from, next) => {
  // 1.如果访问的是登录页面（无需权限），直接放行
  if (to.path === '/login' || to.path === '/register') return next()
  // 2.如果访问的是有登录权限的页面，先要获取token
  const tokenStr = window.localStorage.getItem('token')
  // 2.1如果token为空，强制跳转到登录页面；否则，直接放行
  if (!tokenStr) {
    alert("请登录")
    return next('/login')
  }
  next()
})


