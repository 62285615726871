// 页面接口
import request from "@/utlis/request"
//   登录
const login = "/login"
export default {

    login(data) {
        return request.post(login, data, {
            headers: {
                "Content-Type": "application/json",
            }
        })
    },
}

